
import { arrayOf, nullable, oneOf, oneOfType, shape, string } from 'vue-types';
import { IconNames } from '~/components/atoms/Icon.vue';
import { COLOR_SCHEME_DARK, SIZE_MEDIUM } from '~/constants/General';
import { imageShape } from '~/constants/PropTypes';
export default {
  inheritAttrs: false,
  props: {
    buttonIcon: oneOf(IconNames).def('envelope'),
    visual: arrayOf(shape(imageShape).loose),
    heading: oneOfType([string(), nullable()]),
    body: oneOfType([string(), nullable()]),
    email: oneOfType([string(), nullable()]),
    linkedIn: oneOfType([string(), nullable()]),
    buttonLabel: oneOfType([string(), nullable()]),
  },
  data() {
    return { COLOR_SCHEME_DARK, SIZE_MEDIUM };
  },
};
